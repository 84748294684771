import { ChainId } from '@pulsex/chains'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from '@pulsex/utils/getFullDecimalMultiplier'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const PULSECHAIN_BLOCK_TIME = 10
export const SECOND = 1

export const BASE_PULSECHAIN_SCAN_URLS = {
  [ChainId.PULSECHAIN]: 'https://otter.pulsechain.com',
  [ChainId.PULSECHAIN_TESTNET]: 'https://scan.v4.testnet.pulsechain.com/#',
}

export const ENABLE_STABLESWAP = true

export const SECONDS_PER_YEAR = 31536000
export const BASE_PULSECHAIN_SCAN_URL = BASE_PULSECHAIN_SCAN_URLS[ChainId.PULSECHAIN]
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 375000n
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const DEFAULT_VERSION = 1
export const projectId = 'aa241e445efe7ebf5d557e2441eccc0f'
