import { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import {
  Heading,
  Text,
  Flex,
  Loading,
  SearchInput,
  FlexLayout,
  PageHeader,
  Toggle,
  ToggleView,
} from '@pulsex/uikit'
import { styled } from 'styled-components'
import { useFarms, usePollFarmsWithUserData, usePriceINCUsdc } from 'state/farms/hooks'
import { CurrencyLogo } from '@pulsex/widgets-internal'
import { useIntersectionObserver } from '@pulsex/hooks'
import { INC } from '@pulsex/tokens'
import { DeserializedFarm, FarmWithStakedValue, filterFarmsByQuery } from '@pulsex/farms'
import { useTranslation } from '@pulsex/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { getFarmApr } from 'utils/apr'
import orderBy from 'lodash/orderBy'
import isArchivedPid from 'utils/farmHelpers'
import { useUserFarmStakedOnly, useUserFarmsViewMode } from 'state/user/hooks'
import { ViewMode } from 'state/user/actions'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Table from './components/FarmTable/FarmTable'
import { FarmTypesFilter } from './components/FarmTypesFilter'
import { FarmTabButtons } from './components/FarmTabButtons'
import FarmCard from './components/FarmCard/FarmCard'

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 0px;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0px 4px;
    }
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${Text} {
    margin-left: 8px;
  }
`

const NUMBER_OF_FARMS_VISIBLE = 12

export const getDisplayApr = (incentiveRewardsApr?: number, lpRewardsApr?: number) => {
  if (incentiveRewardsApr && lpRewardsApr) {
    return (incentiveRewardsApr + lpRewardsApr).toLocaleString('en-US', { maximumFractionDigits: 2 })
  }
  if (incentiveRewardsApr) {
    return incentiveRewardsApr.toLocaleString('en-US', { maximumFractionDigits: 2 })
  }
  return null
}

const Farms: React.FC = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()
  const { data: farmsLP, userDataLoaded, regularIncPerSecond } = useFarms()
  const incPrice = usePriceINCUsdc()
  const searchQuery = searchParams.get('search')

  const [_query, setQuery] = useState('')
  const normalizedUrlSearch = useMemo(() => (typeof searchQuery === 'string' ? searchQuery : ''), [searchQuery])
  const query = normalizedUrlSearch && !_query ? normalizedUrlSearch : _query

  const [viewMode, setViewMode] = useUserFarmsViewMode()
  const { address: account } = useAccount()
  const [sortOption] = useState('hot')
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const chosenFarmsLength = useRef(0)

  const isArchived = pathname.includes('archived')
  const isInactive = pathname.includes('history')
  const isActive = !isInactive && !isArchived

  usePollFarmsWithUserData()

  // Users with no wallet connected should see 0 as Earned amount
  // Connected users should see loading indicator until first userData has loaded
  const userDataReady = !account || (!!account && userDataLoaded)

  const [stakedOnly, setStakedOnly] = useUserFarmStakedOnly(isActive)
  const [v1FarmOnly, setV1FarmOnly] = useState(false)
  const [v2FarmOnly, setV2FarmOnly] = useState(false)
  const [farmTypesEnableCount, setFarmTypesEnableCount] = useState(0)

  const activeFarms = farmsLP.filter((farm) => farm.multiplier !== '0X' && !isArchivedPid(farm.pid))
  const inactiveFarms = farmsLP.filter((farm) => farm.multiplier === '0X' && !isArchivedPid(farm.pid))
  const archivedFarms = farmsLP.filter((farm) => isArchivedPid(farm.pid))

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedFarms = archivedFarms.filter(
    (farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
  )

  const farmsList = useCallback(
    (farmsToDisplay: DeserializedFarm[]): FarmWithStakedValue[] => {
      const farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
        if (!farm.lpTotalInQuoteToken || !farm.quoteTokenPriceUsd) {
          return farm
        }
        const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(farm.quoteTokenPriceUsd)
        const { incentiveRewardsApr, lpRewardsApr } =
          isActive && chainId
            ? getFarmApr(new BigNumber(farm?.poolWeight ?? 0), regularIncPerSecond, incPrice, totalLiquidity, farm.lpAddress)
            : { incentiveRewardsApr: 0, lpRewardsApr: 0 }

        return { ...farm, apr: incentiveRewardsApr ?? undefined, lpRewardsApr, liquidity: totalLiquidity }
      })

      return filterFarmsByQuery(farmsToDisplayWithAPR, query)
    },
    [incPrice, query, isActive, chainId, regularIncPerSecond],
  )

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const [numberOfFarmsVisible, setNumberOfFarmsVisible] = useState(NUMBER_OF_FARMS_VISIBLE)

  const chosenFarms = useMemo(() => {
    let chosenFs: FarmWithStakedValue[] = []
    if (isActive) {
      chosenFs = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
    }
    if (isInactive) {
      chosenFs = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
    }
    if (isArchived) {
      chosenFs = stakedOnly ? farmsList(stakedArchivedFarms) : farmsList(archivedFarms)
    }

    if (v1FarmOnly || v2FarmOnly) {
      chosenFs = chosenFs.filter(
        (farm) =>
          (v1FarmOnly && farm.protocol === 'V1') ||
          (v2FarmOnly && farm.protocol === 'V2')
      )
    }
    return chosenFs
  }, [
    activeFarms,
    farmsList,
    inactiveFarms,
    archivedFarms,
    isActive,
    isInactive,
    isArchived,
    stakedArchivedFarms,
    stakedInactiveFarms,
    stakedOnly,
    stakedOnlyFarms,
    v1FarmOnly,
    v2FarmOnly
  ])

  const chosenFarmsMemoized = useMemo(() => {
    const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'apr':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.apr) + Number(farm.lpRewardsApr), 'desc')
        case 'multiplier':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
            'desc',
          )
        case 'earned':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
            'desc',
          )
        case 'liquidity':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
        case 'latest':
          return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.pid), 'desc')
        default:
          return farms
      }
    }

    return sortFarms(chosenFarms).slice(0, numberOfFarmsVisible)
  }, [chosenFarms, sortOption, numberOfFarmsVisible])

  chosenFarmsLength.current = chosenFarmsMemoized.length

  useEffect(() => {
    if (isIntersecting) {
      setNumberOfFarmsVisible((farmsCurrentlyVisible) => {
        if (farmsCurrentlyVisible <= chosenFarmsLength.current) {
          return farmsCurrentlyVisible + NUMBER_OF_FARMS_VISIBLE
        }
        return farmsCurrentlyVisible
      })
    }
  }, [isIntersecting])

  const cardLayout = (
    <>
      {chosenFarmsMemoized.map((farm) => (
        <FarmCard
          key={farm.pid}
          farm={farm}
          displayApr={getDisplayApr(farm.apr, farm.lpRewardsApr) ?? ''}
          incPrice={incPrice}
          account={account}
          removed={false}
        />
      ))}
    </>
  )

  return (
    <PageHeader>
      <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
        <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
          <Heading as="h1" scale="xl" color="secondary" mb="24px">
            {t('Farms')}
          </Heading>
          <Heading scale="md" color="text">
            {t('Stake LP tokens and earn INC')}
          </Heading>
          <Flex alignItems="center" mt="12px">
            <CurrencyLogo currency={INC[chainId]} style={{ marginRight: '4px' }} />
            <Text color="textSubtle" fontSize="14px" bold>
              Inflation: {regularIncPerSecond} INC/sec
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <ControlContainer>
        <ViewControls>
          <ToggleView idPrefix="clickFarm" viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />
          <FarmTabButtons hasStakeInFinishedFarms={stakedInactiveFarms.length > 0} />
          <FarmTypesFilter
            v1FarmOnly={v1FarmOnly}
            handleSetV1FarmOnly={setV1FarmOnly}
            v2FarmOnly={v2FarmOnly}
            handleSetV2FarmOnly={setV2FarmOnly}
            farmTypesEnableCount={farmTypesEnableCount}
            handleSetFarmTypesEnableCount={setFarmTypesEnableCount}
          />
          <ToggleWrapper>
            <Toggle
              id="staked-only-farms"
              checked={stakedOnly}
              onChange={() => setStakedOnly(!stakedOnly)}
              scale="sm"
            />
            <Text> {t('Staked only')}</Text>
          </ToggleWrapper>
        </ViewControls>
        <FilterContainer>
          <LabelWrapper>
            <SearchInput onChange={handleChangeQuery} placeholder="Search Farms" />
          </LabelWrapper>
        </FilterContainer>
      </ControlContainer>
      {viewMode === ViewMode.TABLE ? (
        <Table farms={chosenFarmsMemoized} incPrice={incPrice} userDataReady={userDataReady} />
      ) : (
        <FlexLayout>{cardLayout}</FlexLayout>
      )}
      {account && !userDataLoaded && stakedOnly && (
        <Flex justifyContent="center" mb="4px">
          <Loading />
        </Flex>
      )}
      <div ref={observerRef} />
    </PageHeader>
  )
}

export default Farms
